import React from 'react'
import dotryt from '../assets/images/dotryt.png'
import { Link } from 'react-router-dom'
import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'

function OurOfferings() {
    return (
        <div className="inr_sec bgclr01 scrn01 fngrbg04">
            <div className="container">

                <div className="boxsec ">
                    <div className="backnav my-4">
                        <Link to="/" ><img src={back_nav_icon} alt="back" srcset="" /> </Link>
                    </div>
                    <div className="lftbox">
                        <h3 className="h3txt">OUR OFFERINGS</h3>

                        <p className="ptxt">We strive to deliver exceptional, customer-focused solutions that drive client success and satisfaction and to achieve and maintain a leading position in the IT industry through excellence in products, services, and customer relations. Our goal is to expand and solidify our presence in global markets, offering world-class IT solutions worldwide through our various infrastructure services which we offer to our clients –</p>

                        <h6 className="h5txt">NETWORK SERVICES</h6>
                        <p className="ptxt">We provide technical Services around <b>Design and Build of Network Architecture.</b> These services are essential for the efficient operation and management of computer networks, supporting both individual users and organizational needs.</p>

                        <h6 className="h5txt">DATACENTRE SERVICES</h6>
                        <p className="ptxt">These services are critical for businesses, organization and individuals who needs reliable and secure data management solutions. We provide technical services across <b>Virtualization, Storage, HCI, Disaster Recovery and Migration.</b></p>

                        <h6 className="h5txt">SECURITY SERVICES</h6>
                        <p className="ptxt">We Consult and Implement <b>Security Framework</b> for <b>Information Security</b> and <b>Data Security</b>, which is designed to protect networks, data applications and infrastructure from unauthorized access, cyberattacks and other threats.</p>

                        <h6 className="h5txt">CLOUD SERVICES</h6>
                        <p className="ptxt">We Deploy and Operate Infrastructure on <b>Public Clouds</b> like <b>AWS</b> and <b>Azure</b> along with <b>Hybrid Datacenter Integrations.</b> Offering scalability, flexibility and cost-efficiency enabling business to innovate and grow rapidly.</p>

                        <h6 className="h5txt">MAINTENANCE CONTRACTS</h6>
                        <p className="ptxt">We run <b>SLA Based</b> or <b>Scope Based</b> contracts for <b>Post Implementation Services</b> along with <b>Hardware Maintenance Services.</b></p>

                        <h6 className="h5txt">RESOURCING AND STAFFING</h6>
                        <p className="ptxt">Effective resourcing and staffing ensure that an organization has the right people in the right roles at the right time. We provide <b>On Site Engineers, Resident Engineers</b> or <b>Staffing</b> across technologies including <b>Application Resources</b>.</p>

                        <h6 className="h5txt">PASSIVE AND SURVEYS</h6>
                        <p className="ptxt">We provide valuable tools for gathering insights and understanding the need by doing <b>Passive Survey, Wireless Surveys</b> along with passive work for Site Built or Readiness.</p>
                    </div>
                </div>
            </div>
            <picture className="bgshep lbgshep">
                <img src={dotryt} alt="dotryt" />
            </picture>
        </div>

    )
}

export default OurOfferings