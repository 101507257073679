import React from 'react'
import dotryt from '../assets/images/dotryt.png'
import { Link } from 'react-router-dom'
import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'

function OurVision() {
    return (
        <div className="inr_sec bgclr02 scrn02 fngrbg02">
            <div className="container">
                <div className="boxsec">
                    <div className="backnav my-4">
                        <Link to="/" ><img src={back_nav_icon} alt="back" srcset="" /> </Link>
                    </div>
                    <div className="lftbox">
                        <h3 className="h3txt">OUR VISION</h3>
                        {/* <p className="ptxt">We envision a world where technology seamlessly integrates into every facet of life, driving innovation and fostering growth. We strive to deliver solutions that transform industries and empower businesses to thrive in the digital age.</p> */}
                        <p className="ptxt">At IDENTIQA, we believe in a client centric approach that priorities understanding and addressing the specific needs of each client. We focus on continuous innovation and improvement, fostering a culture of excellence and collaboration.</p>
                        <p className="ptxt">We are working to make a positive impact on society through technology-driven solutions and corporate social responsibility initiatives.</p>
                        <h6 className="h5txt">We have OUR -</h6>
                        <ul>
                            <li>Core technical team with OEM.</li>
                            <li>Core technical team with domain knowledge</li>
                            <li>Certifications and cross domain knowledge</li>
                            <li>Hybrid or Remote Delivery Model</li>
                            <li>Skills in line with project requirements</li>
                            <li>Single point of escalation for technical calls</li>
                        </ul>
                        <h6 className="h5txt">IDENTIQA Core Team is -</h6>
                        <ul>
                            <li>Delivering HLD, LLD, Migration and Implementation plans for clients</li>
                            <li>Delivering green field and brown field projects for clients</li>
                            <li>ITSM and ITIL process driven</li>
                            <li>Delivering Complex and multi technology implementation and integrations.</li>
                        </ul>


                        <div className="clntsec">
                            <ul>

                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture20.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture21.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture22.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture23.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture24.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture25.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture32.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture33.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture26.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture27.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture28.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture29.png`} alt="clients" /></li>

                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture30.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture31.png`} alt="clients" /></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <picture className="bgshep lbgshep">
                <img src={dotryt} alt="dotryt" />
            </picture>
        </div>

    )
}

export default OurVision