import React, { useEffect, useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        date: new Date()
    });

    const [errors, setErrors] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);

    useEffect(() => {
        if (formSubmit) {
            setTimeout(() => {
                setFormSubmit(false)
            }, 3000);
        }
    }, [formSubmit])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let formErrors = {};
        let valid = true;

        if (!formData.firstName) {
            valid = false;
            formErrors.firstName = "First name is required";
        }

        if (!formData.lastName) {
            valid = false;
            formErrors.lastName = "Last name is required";
        }

        if (!formData.email) {
            valid = false;
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            valid = false;
            formErrors.email = "Email address is invalid";
        }

        if (!formData.phone) {
            valid = false;
            formErrors.phone = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            valid = false;
            formErrors.phone = "Phone number is invalid";
        }

        if (!formData.message) {
            valid = false;
            formErrors.message = "Message is required";
        }

        setErrors(formErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {

                await addDoc(collection(db, "contacts"), formData);
                // console.log("Document written with ID: ", docRef.id);
                // Clear form after successful submission
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                setErrors({});
                setFormSubmit(true)
                // alert("Thanks for submitting!");

            } catch (e) {

                alert("Something went wrong. Please try again");
                console.error("Error adding document: ", e);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="frmsec">
                {formSubmit ? <div class="col-12 mt-1"><p>Thanks for submiting!</p></div> : <div className="row g-lg-2 g-3">
                    <div className="col-6">
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="First name"
                            aria-label="First name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                        {errors.firstName && <div className="error">{errors.firstName}</div>}
                    </div>
                    <div className="col-6">
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Last name"
                            aria-label="Last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                    </div>
                    <div className="col-6">
                        <input
                            type="email"
                            className="form-control form-control-sm"
                            placeholder="Email"
                            aria-label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <div className="error">{errors.email}</div>}
                    </div>
                    <div className="col-6">
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Phone"
                            aria-label="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        {errors.phone && <div className="error">{errors.phone}</div>}
                    </div>
                    <div className="col-12">
                        <textarea
                            className="form-control"
                            placeholder="Type your message/enquiry here"
                            aria-label="Address"
                            rows={3}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                        {errors.message && <div className="error">{errors.message}</div>}
                    </div>
                    <div className="col-12 sbmtbtn">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>

                    <p className="mt-3 text-dark fs-10">
                        By providing a telephone number and submitting this form you are consenting to be contacted by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
                    </p>

                </div>}

            </div>
        </form>
    );
};

export default ContactForm;
