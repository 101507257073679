import React, { useEffect, useRef } from 'react';
import Datamap from 'datamaps'; // Import the Datamaps library
import 'datamaps/dist/datamaps.world.min.js'; // Import the world map


const Test = () => {
    const mapContainer = useRef(null);

    useEffect(() => {
        if (mapContainer.current) {
            // Initialize the Datamap
            const mapInstance = new Datamap({
                element: mapContainer.current,
                responsive: true,
                // projection: 'mercator', // Projection type
                projection: 'equirectangular',
                fills: {
                    defaultFill: "#689",
                    authorHasTraveledTo: "#fa0fa0",
                    IND: "#fa0fa0",
                    USA: "#fasss",
                },
                data: {
                    IND: { fillKey: "authorHasTraveledTo" },
                    USA: { fillKey: "authorHasTraveledTo" },
                },

                // Hover behavior
                geographyConfig: {
                    dataUrl: null, //if not null, datamaps will fetch the map JSON (currently only supports topojson)
                    hideAntarctica: true,
                    borderWidth: 1,
                    borderOpacity: 1,
                    borderColor: '#FDFDFD',
                    popupTemplate: function (geography, data) { //this function should just return a string
                        let officeAdd = ""
                        if (geography.id === "IND") {

                            officeAdd = `<p>${geography.properties.name}</p><p> 20, Okhla Area Phase 3, New Delhi - 110020 </p>`
                            // officeAdd = "20, Okhla Area Phase 3, New Delhi - 110020"
                        } else if (geography.id === "USA") {
                            officeAdd = `<p>${geography.properties.name}</p><p> 8 THE GREEN , STE A DOVER, DE 19901</p>`
                        } else {
                            officeAdd = geography.properties.name
                        }
                        return '<div class="hoverinfo p-2">' + officeAdd + '</div>';
                    },
                    popupOnHover: true, //disable the popup while hovering
                    highlightOnHover: true,
                    highlightFillColor: '#FC8D59',
                    highlightBorderColor: 'rgba(250, 15, 160, 0.2)',
                    highlightBorderWidth: 2,
                    highlightBorderOpacity: 1
                },
                // Zoom behavior
                zoomOnScroll: false,
                // tooltips enabled
                tooltips: true,
            });

            // Clean up on component unmount
            return () => {
                mapInstance && mapInstance.svg.remove();
            };
        }
    }, []);

    return (
        <div className="container bg-light">
            <div
                ref={mapContainer}
                style={{ width: "100%", height: "500px" }}
            />
        </div>
    );
};

export default Test;