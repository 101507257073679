import React from 'react'
import dotryt from '../assets/images/dotryt.png'
import { Link } from 'react-router-dom'
import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'

function OurProject() {
    return (
        <div className="inr_sec bgclr03 scrn03 fngrbg01">
            <div className="container">
                <div className="boxsec">
                    <div className="backnav my-4">
                        <Link to="/" ><img src={back_nav_icon} alt="back" srcset="" /> </Link>
                    </div>
                    <div className="lftbox">
                        <h3 className="h3txt">OUR PROJECTS</h3>
                        {/* <p className="ptxt">We are team of seasoned professionals with deep industry knowledge and technical expertise. We have a history of successful projects and satisfied clients across various industries with our dedicated support team who ensures seamless implementation and ongoing maintenance.</p> */}
                        <p className="ptxt">At IDENTIQA, as we pave the way for a technologically advanced future, driving innovation and creating value for our clients every step of the way. Together, we can achieve extraordinary outcomes in the digital world. Our core team successfully completed major projects –</p>
                        <h6 className="h5txt">NETWORK PROJECTS</h6>
                        <ul>
                            <li>Green field ACI deployment on 2 Spine and 6 leaf architecture.</li>
                            <li>SD WAN implementation for 47 retail outlets on Fortinet.</li>
                            <li>Infoblox DNS migration from On Prem to AWS and DNS based application routing.</li>
                            <li>F5 BIG IP Application Load Balancer and SSL off loader deployment for 10 application farms.</li>
                            <li>Brown field migration to ACI from N5k and N2k for 42 racks.</li>
                            <li>SD WAN for 118 retail outlets on Meraki SD WAN across two geographics.</li>
                        </ul>
                        <h6 className="h5txt">SECURITY PROJECTS</h6>
                        <ul>
                            <li>IPSec and remote VPN deployments.</li>
                            <li>Cisco NAC with posturing and profiling for 1800 users.</li>
                            <li>Keysight Packet Broker deployment for Global traffic analysis.</li>
                            <li>MDM and DLP deployment for 190 users on M365.</li>
                            <li>Multiple firewall deployments for Cisco Fire Power, Palo</li>
                            <li>Alto and Checkpoint.</li>
                            <li>End user AV and DLP deployments.</li>
                            <li>Cisco umbrella for cloud-based DNS security.</li>
                            <li>Palo Alto fire adoption with migration from existing services.</li>
                        </ul>
                        <h6 className="h5txt">OTHER MAJOR PROJECTS</h6>
                        <ul>
                            <li>Career grade F5 DNS management and support for Global ISP.</li>
                            <li>Palo Alto and Meraki implementation and deployment for 14 countries in Africa.</li>
                            <li>Arbor DDoS implementation and support for Global ISP.</li>
                            <li>Azure cloud-based GPS monitoring and tracking solutions for Gol.</li>
                            <li>5G Readiness Network upgrade for Global ISP across 44 locations.</li>
                            <li>B2B technical support for 5000 locations across India for network issues.</li>
                            <li>Infrastructure builds on Azure Cloud for Hospital Digitization for ABHA scheme for state government.</li>
                        </ul>
                        <h6 className="h5txt">RESOURCE AND HARDWARE PROJECTS</h6>
                        <ul>
                            <li>Resource Augmentation for L1, L2, L3 and L4 resources for JIO Convention Centre in Mumbai.</li>
                            <li>SLA based for IT infrastructure.</li>
                            <li>Skill Based Resource Augmentation for leading OEMs like DellEMC and HP.</li>
                            <li>PNB bank managed services for network.</li>
                            <li>Data centre and Campus cabling as part of build projects.</li>
                            <li>Wireless surveys as part of Wireless deployments.</li>
                        </ul>
                        <h6 className="h5txt">OUR CLIENTS</h6>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture2.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture3.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture4.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture5.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture6.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture7.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture8.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture9.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture10.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture11.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture12.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture13.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture14.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture15.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture16.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture17.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture18.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture19.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture20.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture21.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture22.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture23.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture24.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture25.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture26.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/our-project-logo/Picture27.png`} alt="clients" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <picture className="bgshep lbgshep">
                <img src={dotryt} alt="dotryt" />
            </picture>
        </div>

    )
}

export default OurProject