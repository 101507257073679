import React from 'react'
import hlogo from '../assets/images/hlogo.png'
import { Link } from 'react-router-dom'

function Header() {

    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const [isSticky, setIsSticky] = React.useState(false);
    const menuRef = React.useRef(null);


    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };



    const handleClickOutside = (event) => {
        // Check if the click is outside the menu
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };


    React.useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener("click", handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    // scroll handler
    React.useEffect(() => {

        const handleScroll = () => {
            const offset = window.scrollY;
            console.log(offset)
            const stickyPoint = 63; // You can change this value to whatever suits your needs
            setIsSticky(offset > stickyPoint);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`hdrsec ${isSticky ? 'header-bg' : ''}`} >
            <nav className="navbar navbar-expand-lg p-0">
                <div className="container-fluid">
                    <Link className="navbar-brand logo" to="/">
                        <img src={hlogo} alt="logo" />
                    </Link><div className="mbtn">
                        <div role="navigation">
                            <div id="menuToggle">
                                <input type="checkbox" onChange={(e) => toggleMenu()} checked={isMenuOpen} ref={menuRef} />
                                <span>xyz</span>
                                <span>xyz</span>
                                <span>xyz</span>
                                <ul id="menu">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/our-vision">Our Vision</Link></li>
                                    <li><Link to="/our-offering">Our Offerings</Link></li>
                                    <li><Link to="/how-we-execute">How We Execute</Link></li>
                                    <li><Link to="/our-project">Our Projects</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header