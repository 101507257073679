import React from 'react'
import dotryt from '../assets/images/dotryt.png'
import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'
import { Link } from 'react-router-dom'


// console.log(logos)
function HowWeExcute() {
    return (
        <div className="inr_sec scrn04 bgclr04 fngrbg05">
            <div className="container">
                <div className="boxsec">
                    <div className="backnav my-4">
                        <Link to="/" ><img src={back_nav_icon} alt="back" srcset="" /> </Link>
                    </div>
                    <div className="lftbox">
                        <h3 className="h3txt">HOW WE EXECUTE</h3>

                        <h6 className="h5txt">DATACENTRE DESIGN AND BUILD</h6>
                        <p className="ptxt">Designing and building a data centre involves careful planning, expert engineering and adherence to industry standards to ensure high performance, reliability and scalability. Here is an overview of the key steps and considerations we follow –</p>
                        <ul>
                            <li><strong>Solution Design</strong> for Application Infrastructure, Branch Network, User across Zero Touch Architecture.</li>
                            <li><strong>Built Application Flow</strong> with respect to network traffic and security best practices.</li>
                            <li><strong>Security Architecture</strong> for Cloud Infrastructure, Branch Secure access, User Identity and Access control.</li>
                            <li><strong>Phased Implementation</strong> of Proposed Solution along with ramp-up plan for Transition to Operations.</li>
                            <li><strong>Managing and operating</strong> the infrastructure 24x7 for ~100 Sites 500 users with 100% YoY projection.</li>
                        </ul>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture1.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture2.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture3.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture4.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture5.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture6.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture7.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture8.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture9.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture10.png`} alt="clients" /></li>
                            </ul>
                        </div>
                        <h6 className="h5txt">MANAGED DNS SERVICES</h6>
                        <p className="ptxt">Managed DNS services involved outsourcing the DNS management to specialised providers. The providers operate globally distributed network of DNS servers that resolve domain names into IP addresses, ensuring that users can reach websites, applications and services efficiently and reliably, while focusing on their core business operations.</p>
                        <p className="ptxt">We at IDENTIQA ensure users (our clients) through DNS - Improved performance, Increase reliability, Enhanced security, Cost efficiency and simplified management. Our objective is to –</p>
                        <ul>
                            <li>Manage <strong>DNS Infrastructure</strong> for the ISP across 17 Circles in India.</li>
                            <li><strong>SLA</strong> based 24x7 support.</li>
                            <li><strong>Manage and Operate</strong> the existing install base of the F5 and Infoblox infrastructure.</li>
                            <li><strong>Design and Execute</strong> hardware and software upgrades.</li>
                            <li>Build <strong>capacity augmentation plan</strong> and execution.</li>
                            <li><strong>Configuration build</strong> and validation as per ISP and GoI guidelines.</li>
                            <li><strong>Field Support</strong> for hardware replacement and RMA.</li>
                        </ul>

                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture11.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture6.png`} alt="clients" /></li>
                            </ul>
                        </div>

                        <h6 className="h5txt">MANAGED DDoS SERVICES</h6>
                        <p className="ptxt">Managed DDoS (Distributed Denial of Service) protection services are specialised solutions provided to our clients to protect organisations from DDoS attacks. By leveraging the expertise and infrastructure of specialised providers, business can ensure their services remain available, secure and performant, even in the face of large -scale attacks. This allows organisations to focus on their core operations without worrying about the impact of malicious traffic. Through these DDos services we ensure our clients to -</p>
                        <ul>
                            <li>Manage DDoS Infrastructure for the ISP across Globe.</li>
                            <li>ISP is using NetScout as an OEM for the Deployment at IXP.</li>
                            <li><strong>SLA</strong> based 24x7 support.</li>
                            <li><strong>Manage and Operate</strong> the existing install base of the NetScout in India, Los Angeles, Singapore and MRS.</li>
                            <li><strong>Design and Execute</strong> hardware and software upgrades.</li>
                            <li>Build <strong>capacity augmentation plan</strong> and execution.</li>
                            <li><strong>Reporting</strong> of traffic scrubbing and Data transfer.</li>
                            <li><strong>Field Support</strong> for hardware replacement and RMA.</li>
                        </ul>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture13.png`} alt="clients" /></li>
                            </ul>
                        </div>
                        <h6 className="h5txt">IMPLEMANTATION SERVICES</h6>
                        <p className="ptxt">Implementation services are essential for successfully deploying and integrating new technologies within an organization. By partnering with IDENTIQA, Businesses can ensure their new systems are effectively implemented, optimized, and aligned with their strategic goals. This led to improved performance, reduced risks and greater user satisfaction.</p>
                        <ul>
                            <li>Our objective is to Design and Implement network infrastructure across 14 countries.</li>
                            <li>Design and Implement <strong>Palo Alto Firewalls</strong> and <strong>Meraki Appliances</strong>.</li>
                            <li><strong>Design</strong> the network architecture based on the current deployment.</li>
                            <li><strong>Built</strong> migration plan for each site and service.</li>
                            <li><strong>Migrated</strong> existing configuration to the proposed appliances.</li>
                            <li><strong>Build</strong> cloud policies for the Meraki appliances.</li>
                            <li><strong>Configured and Installed</strong> the setup, in parallel for traffic testing and validation.</li>
                            <li><strong>Migrated</strong> the traffic to the infrastructure during downtime.</li>
                        </ul>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture7.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture14.png`} alt="clients" /></li>
                            </ul>
                        </div>
                        <h6 className="h5txt">SD-WAN IMPLEMENTATION SERVICES</h6>
                        <p className="ptxt">SD-WAN (software defined wide area network) services are crucial for organisations looking to modernise their network infrastructure, improve performance and enhance security. We provide this service to our clients which involve the deployment and integration of SD-WAN technology to optimize and secure the network connectivity of organisation. It ensures the efficient management of WAN resources and improve overall network performance.</p>
                        <ul>
                            <li>We Design and Implement SDWAN for Brown and Green field Sites.</li>
                            <li><strong>Deployment of Meraki SDWAN for 192 locations across India.</strong></li>
                            <li><strong>Design Workshop</strong> with client to understand current architecture and traffic flow.</li>
                            <li><strong>Design</strong> the network architecture based on the current deployment.</li>
                            <li><strong>Built</strong> migration plan for each site and service.</li>
                            <li><strong>Build</strong> cloud policies for the Meraki appliances.</li>
                            <li><strong>Configured and Installed</strong> the setup, in parallel for traffic testing and validation.</li>
                            <li><strong>Migrated</strong> the traffic to the infrastructure during downtime.</li>
                            <li><strong>Deployed</strong> VMX on Cloud for Cloud Traffic.</li>
                            <li><strong>Phased Migration</strong> of 192 locations across India to MX67.</li>
                        </ul>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture7.png`} alt="clients" /></li>
                            </ul>
                        </div>
                        <h6 className="h5txt">CLOUD IMPLEMENTATION AND OPERATIONS</h6>
                        <p className="ptxt">Cloud implementation services involve the process of planning, deployment and managing cloud computing solutions to optimize an organization’s IT infrastructure. These services are crucial for organization to leverage the benefits of cloud computing and to achieve greater scalability, performance and cost-efficiency. By using our Cloud implementation services, IDENTIQA help organizations stay competitive, innovate rapidly and adapt to change market demand. Here we -</p>
                        <ul>
                            <li>Build infrastructure for Digital Hospital Project.</li>
                            <li><strong>Built</strong> infrastructure on Azure as per application requirements.</li>
                            <li><strong>Designed</strong> IP Schema and VNET for inter VPC peering.</li>
                            <li><strong>Configured</strong> Domain, SSL and VPN policies for access to policies.</li>
                            <li><strong>Secured</strong> the infrastructure with Firewall and IAM policies.</li>
                            <li><strong>Integrated</strong> remote application instances to cloud in a distributed deployment across 100+ hospitals across the state.</li>
                            <li><strong>Managing and Operating</strong> the infrastructure 24x7</li>
                        </ul>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture1.png`} alt="clients" /></li>
                            </ul>
                        </div>
                        <h6 className="h5txt">CLOUD INFRASTRUCTURE MANAGED SERVICES
                        </h6>
                        <p className="ptxt">Cloud infrastructure managed services are essential for organisations looking to leverage the full potential of cloud computing while minimizing the complexities and risk associated with managing cloud environments.</p>
                        <p className="ptxt">By partnering with IDENTIQA for these services, we ensure our clients their cloud infrastructure is secure, cost efficient and aligned with their strategic goals, which allow their organisation to focus on innovation and growth while leaving the day-to-day management of cloud resources to our experts. With our services we -</p>
                        <ul>
                            <li><strong>Built</strong> SD WAN controller on Azure as per application requirements.</li>
                            <li><strong>Solution Design</strong> for end Client on behalf of ISP.</li>
                            <li><strong>Solution Sizing</strong> based on end customer discussions.</li>
                            <li><strong>Perform POC</strong> on behalf of ISP for agreed success criteria.</li>
                            <li><strong>Implementation and Migration</strong> of Proposed Solution.</li>
                            <li><strong>Managing</strong> 23 if ISP’s customers on Fortinet SDWAN across India.</li>
                            <li><strong>Managing and Operating</strong> the infrastructure 24x7</li>
                        </ul>
                        <div className="clntsec">
                            <ul>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture1.png`} alt="clients" /></li>
                                <li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture19.png`} alt="clients" /></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <picture className="bgshep lbgshep">
                <img src={dotryt} alt="dotryt" />
            </picture>
        </div>

    )
}

export default HowWeExcute