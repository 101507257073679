// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA4kQk8eQsQLOdcP6vP7Hxvw-wuzeJf_Ok",
    authDomain: "identiqa-tech.firebaseapp.com",
    projectId: "identiqa-tech",
    storageBucket: "identiqa-tech.appspot.com",
    messagingSenderId: "29277341203",
    appId: "1:29277341203:web:bfa08c0ddb5eacb172a1e6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
