import React from 'react'
import image11 from "../assets/images/image11.png"
import path30 from "../assets/images/path30.png"
import screen4 from "../assets/images/screen4.png"
import path32 from "../assets/images/path32.png"
import g3171 from "../assets/images/g3171.png"
import penguineimg from "../assets/images/penguineimg.png"
import path31 from "../assets/images/path31.png"
import dotryt from "../assets/images/dotryt.png"
import path6378 from "../assets/images/path6378.png"
import image3210 from "../assets/images/image3210.png"
import vvimg04 from "../assets/images/vvimg04.png"
import vvimg03 from "../assets/images/vvimg03.png"
import vvimg02 from "../assets/images/vvimg02.png"
import dot1 from "../assets/images/dot1.png"
import dot2 from "../assets/images/dot2.png"
import dot3 from "../assets/images/dot3.png"
import dot4 from "../assets/images/dot4.png"
import { Link } from 'react-router-dom'
import ContactForm from '../components/ContactForm'

function Home() {
	return (

		<div className="hsec">
			<div className="scorlpy">
				<div className="scorlpysec">
					<a className href="#list-item-1"><span>Our Vision</span></a>
					<a className href="#list-item-2"><span>Our Offerings</span></a>
					<a className href="#list-item-3"><span>How We Execute</span></a>
					<a className href="#list-item-4"><span>Our Projects</span></a>
					<a className href="#list-item-5"><span>Contact Us</span></a>
				</div>
			</div>
			<div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>

				{/* Our Vision */}
				<div className="cfwsec hvh100 fngrbg02" id="list-item-1">
					<div className="container">
						<div className="boxsec">
							<div className="rytbox">
								<picture className="imgbx dsktpimg"> <img src={image3210} alt="image11" /> </picture>
							</div>
							<div className="lftbox">
								<h3 className="h3txt">creating a <span>future-ready</span> world </h3>
								<h6 className="h6txt">Strength in Digital Capabilities, Power in Performance</h6>
								<picture className="imgbx mblimg"> <img src={vvimg02} alt="vvimg02" /> </picture>
								<p className="ptxt">We envision a world where technology seamlessly integrates into every facet of life, driving innovation and fostering growth. We strive to deliver solutionsthat transform industries and empower businesses to thrive in the digital age. </p>
								<div className="fngrbtn">
									<Link to="/our-vision"> <img src={path6378} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
						</div>
					</div>
					<picture className="bgshep lbgshep"> <img className="dskdotsec" src={dotryt} alt="dotryt" /> <img className="mbldotsec" src={dot4} alt="dot4" /> </picture>

				</div>

				{/* our offering */}
				<div className="cedsec hvh100 fngrbg04" id="list-item-2">
					<div className="container">
						<div className="boxsec">
							<div className="lftbox">
								<h3 className="h3txt">CORE <span>DIGITAL</span> EXPERTISE </h3>
								<h6 className="h6txt">Core Strengths, Boundless Possibilities</h6>
								<p className="ptxt">Our core aspiration as an IT company is providing digital solutions and becoming a leader in technology innovation and giving exceptional value to customers. </p>
								<picture className="imgbx"> <img src={image11} alt="image11" /> </picture>
								<div className="fngrbtn">
									<Link to="/our-offering"> <img src={path30} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
							<div className="rytbox">
								<picture className="imgbx"> <img src={image11} alt="image11" /> </picture>
							</div>
						</div>
					</div>
					<picture className="bgshep rbgshep"> <img className="dskdotsec" src={g3171} alt="g3171" /> <img className="mbldotsec" src={dot1} alt="dot1" /> </picture>
				</div>


				{/* How we excute */}
				<div className="mowesec hvh100 fngrbg05" id="list-item-3">
					<div className="container">
						<div className="boxsec">
							<div className="lftbox">
								<h3 className="h3txt">Maximize the <span>opportunities</span>
									<span>with our</span> expertise
								</h3>
								<h6 className="h6txt">Excellence rooted in expertise</h6>
								<p className="ptxt">We focus on continuously innovate and develop cutting-edge technologies that revolutionize industries and improve lives and deliver exceptional, customer-focused solutions that drive client success and satisfaction.</p>
								<picture className="imgbx mblimg"> <img src={vvimg03} alt="vvimg03" /> </picture>
								<div className="fngrbtn">
									<Link to="/how-we-execute"> <img src={path31} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
							<div className="rytbox">
								<picture className="imgbx dsktpimg"> <img src={penguineimg} alt="penguineimg" /> </picture>
							</div>
						</div>
					</div>
					<picture className="bgshep rbgshep"> <img className="dskdotsec" src={g3171} alt="g3171" /> <img className="mbldotsec" src={dot3} alt="dot3" /> </picture>

				</div>

				{/* our project */}
				<div className="iibfsec hvh100 fngrbg01" id="list-item-4">
					<div className="container">
						<div className="boxsec">
							<div className="lftbox">
								<h3 className="h3txt">invest in <span>ideas for</span>
									<span>better</span> future
								</h3>
								<h6 className="h6txt">Empowering Innovations and Driving towards the Excellence</h6>
								<p className="ptxt"> We are team of seasoned professionals with deep industry knowledge and technical expertise. We have a history of successful projects and satisfied clients across various industries with our dedicated support team who ensures seamless implementation and ongoing maintenance. </p>
								<div className="fngrbtn">
									<Link to="/our-project"> <img src={path32} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
							<div className="rytbox">
								<picture className="imgbx dsktpimg"> <img src={screen4} alt="screen4" /> </picture>
								<picture className="imgbx mblimg"> <img src={vvimg04} alt="vvimg04" /> </picture>
							</div>
						</div>
					</div>
					<picture className="bgshep rbgshep"> <img className="dskdotsec" src={g3171} alt="g3171" /> <img className="mbldotsec" src={dot2} alt="dot2" /> </picture>

				</div>


				<div className="gitsec hvh100 fngrbg02" id="list-item-5">
					<div className="container">
						<div className="frmtxtsec">
							<h5>our offices</h5>
							<h4>Get in <span>Touch</span>
							</h4>
							<p>Come and visit our quarters or simply send us an email anytime you want. We are open to all suggestions from our clients.</p>
							<div className="box">
								<h6>Bharat Office:</h6>
								<p>20, Okhla Area Phase 3, New Delhi - 110020</p>
							</div>
							<div className="box">
								<h6>USA Office:</h6>
								<p>8 THE GREEN , STE A DOVER, DE 19901</p>
							</div>
							<div className="box">
								<p><a href="mailto:Sales@identiqa.co.in">Sales@identiqa.co.in</a></p>
							</div>
						</div>
						<ContactForm /> </div>
				</div>
			</div>
		</div>

	)
}

export default Home